@use 'sass:map';
@use '../breakpoints/index' as bp;

@function mat-set-background($theme, $backgroundColor) {
	$palette-color: map-get($theme, color);
	$background: map-get($palette-color, background);
	$background: map_merge(
		$background,
		(
			background: $backgroundColor,
		)
	);
	$palette-color: map_merge(
		$palette-color,
		(
			background: $background,
		)
	);
	@return map_merge(
		$theme,
		(
			color: $palette-color,
		)
	);
}

@function change-theme-background($theme, $name, $value) {
	$modified-theme: $theme;
	$theme-color: map.get($theme, color);
	$color-background-palette: map.get($theme-color, background);

	@if $color-background-palette {
		$color-background-palette: map.merge(
			$color-background-palette,
			(
				$name: $value,
			)
		);
	}

	@if $color-background-palette {
		$modified-theme: map.merge(
			$modified-theme,
			(
				color:
					map.merge(
						$theme-color,
						(
							background: $color-background-palette,
						)
					),
			)
		);
	}

	$background-palette: map.get($theme, background);

	@if $background-palette {
		$background-palette: map.merge(
			$background-palette,
			(
				$name: $value,
			)
		);
	}

	@if $background-palette {
		$modified-theme: map.merge(
			$modified-theme,
			(
				background: $background-palette,
			)
		);
	}

	@return $modified-theme;
}

@mixin spacing() {
	$spaces: 0, 2, 4, 6, 8, 12, 16, 18, 24, 32, 36;

	@each $size in $spaces {
		.fx-margin-#{$size} {
			margin: #{$size}px;
		}
	}

	@each $size in $spaces {
		.fx-padding-#{$size} {
			padding: #{$size}px;
		}
	}

	@each $size in $spaces {
		.fx-gap-#{$size} {
			gap: #{$size}px;
		}
	}
}

@mixin heights() {
	$height_values: 12, 16, 18, 20, 24, 28, 32, 36, 40, 42, 46, 48, 52;

	@each $size in $height_values {
		.fx-height-#{$size} {
			height: #{$size}px !important;
			min-height: #{$size}px !important;
		}
	}
}

@mixin self_align() {
	$aligns: (
		'start': flex-start,
		'center': center,
		'end': flex-end,
		'stretch': stretch,
		'baseline': baseline,
		'auto': auto,
	);
	@each $key, $value in $aligns {
		.fx-self-#{$key} {
			align-self: $value;
		}
	}
}

@mixin layouts() {
	.fx-layout {
		$layout_map: 'xs', 'sm', 'md', 'lg', 'xl', 'lt-sm', 'lt-md', 'lt-lg',
			'lt-xl', 'gt-xs', 'gt-sm', 'gt-md', 'gt-lg';
		$directions: (
			'row': row,
			'column': column,
		);

		@each $lt in $layout_map {
			@each $k, $v in $directions {
				&-#{$k}-#{$lt} {
					@include bp.breakpoint($lt) {
						flex-direction: $v !important;
					}
				}
			}
		}
	}
}

@mixin font_sizes() {
	.fz {
		$fonts: 10, 11, 12, 13, 14, 15, 16, 18;
		@each $i in $fonts {
			&-#{$i} {
				font-size: #{$i}px !important;
			}
		}
	}
}

@mixin flex_order() {
	.fx-order {
		$orders: 0, 1, 2, 3, 4, 5;
		@each $i in $orders {
			&-#{$i} {
				order: #{$i};
			}
		}
	}
	.fx-order--1 {
		order: -1;
	}
	.fx-order--2 {
		order: -2;
	}
}

@mixin font_weights() {
	.fw {
		$weights: 300, 400, 500, 600;
		@each $i in $weights {
			&-#{$i} {
				font-weight: #{$i};
			}
		}
	}
}
